// auth 2.0
import { sendStartRecordClipRequestToParentWindow, sendStopRecordClipRequestToParentWindow, sendWakeUpRequestToParentWindow } from "../extensionInterface/sendToExtension";

export const recordClip = ({ applicationType, blockId }) => {
  switch (applicationType) {
    case "extension": {
      sendStartRecordClipRequestToParentWindow(blockId);
      return;
    }
    case "web": {
      // TODO
      return;
    }
    default: {
      return;
    }
  }
};

export const stopRecordClip = ({ applicationType }) => {
  switch (applicationType) {
    case "extension": {
      sendStopRecordClipRequestToParentWindow();
      return;
    }
    case "web": {
      // TODO
      return;
    }
    default: {
      return;
    }
  }
};

export const wakeUpOffscreen = ({ applicationType }) => {
  switch (applicationType) {
    case "extension": {
      sendWakeUpRequestToParentWindow();
      return;
    }
    case "web": {
      // TODO
      return;
    }
    default: {
      return;
    }
  }
};
