import { create } from "zustand";

interface VideoRecordStore {
  videoRecordingNodeKeys: string[];
  setVideoRecordingNodeKeys: (videoRecordingNodeKeys: string[]) => void;
}

export const useVideoRecordStore = create<VideoRecordStore>((set) => ({
  videoRecordingNodeKeys: [],
  setVideoRecordingNodeKeys: (videoRecordingNodeKeys) => set({ videoRecordingNodeKeys }),
}));
